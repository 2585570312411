import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';

const app = createApp(App);

 // 挂载状态管理
app.use(createPinia());

// rem 弹性转换
import "amfe-flexible";

// vant
import Vant from 'vant'
import { ImagePreview, Toast } from 'vant'
import 'vant/lib/index.css';
app.use(Vant)
app.use(ImagePreview)
app.use(Toast)
import { ConfigProvider } from 'vant';
app.use(ConfigProvider);

app.use(router).mount('#app');
