import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/report/list',
    name: 'ReportList',
    component: () => import('@/views/report/list/index.vue')
  },
  {
    path: '/report/detail',
    name: 'ReportDetail',
    component: () => import('@/views/report/detail/index.vue')
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
